import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, Third } from "../layout/container"
import List from "../ui/list"

import Discover from "../../images/illustrations/discover.svg"
import Craft from "../../images/illustrations/craft.svg"
import Tell from "../../images/illustrations/tell.svg"

const How = ({ theme }: any) => {
  const Section = styled.section`
    padding: 1rem 0 5rem;
    background: ${theme.colors.light};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h1 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 50px;
      }

      & + p {
        margin-bottom: 5rem;
      }
    }

    svg {
      max-width: 33%;
      width: 300px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        max-width: 50%;
        height: 200px;
        margin: 0 auto;
      }
      & + div {
        width: 60%;
        margin-bottom: 3rem;
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          width: 100%;
        }
      }
    }

    h3 {
      font-size: 55px;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
        text-align: center;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        font-style: italic;
        color: ${theme.colors.accent};
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          font-size: 25px;
        }
      }
    }
  `

  const YouGet = styled.section`
    padding: 5rem 0;
    background: ${theme.colors.primary};
    color: ${theme.colors.light};
    @media screen and (max-width: ${theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h2 {
      color: ${theme.colors.light};
      text-align: center;
    }
  `

  return (
    <React.Fragment>
      <Section id="how">
        <Container>
          <h1>
            The <em>amp</em> programme
          </h1>
          <p>
            A three-step programme designed to set you up as a thought leading
            CEO in just 180 days. Each step of the way, you will achieve defined
            milestones and build important assets that will be key to
            establishing your CEO brand.
          </p>
          <Row>
            <Discover />
            <div>
              <h3>
                discover <span>your story</span>
              </h3>
              <p>
                The first phase will help you discover your authentic voice and
                build your story. This will involve us running workshops for you
                and your team to analyse your business, key competitors and
                target audiences to ensure this voice resonates.
              </p>
              <p>
                To get media coverage you need to understand what journalists
                need to hear. In the first two months a one-on-one day with a
                tier 1 journalist covering your industry will give you the
                insight needed to become media-ready. That partnership will be
                built throughout &nbsp;<em>amp</em> helping you further define
                your new, authentic editorial voice.
              </p>
            </div>
          </Row>
          <Row>
            <Craft />
            <div>
              <h3>
                craft <span>your story</span>
              </h3>
              <p>
                We are ready to start creation and distribution. The{" "}
                <em>amp</em>
                &nbsp;team will begin writing and designing your blog posts and
                articles. Our social media expert will conduct a bespoke social
                media audit, host workshops for your team and help with any
                revamp of digital messaging. The aim: your online profile should
                match your offline personality.
              </p>
              <p>
                Also, your media training shifts into a higher gear. A bespoke
                training day at the London studios of our partner agency freuds
                means you benefit from the expertise that has helped many of the
                world’s biggest brands. The freuds experts will help build your
                on-camera persona and prepare you for an upcoming “live”
                scenario that will test your newly-developed skills.
              </p>
            </div>
          </Row>
          <Row>
            <Tell />
            <div>
              <h3>
                tell <span>your story</span>
              </h3>
              <p>
                Time to move from theory to practice. A roundtable of
                influencers and journalists will serve as a platform to tell
                your story on industry issues, using the skills you have built
                on <em>amp</em>. Text and video content created from this event
                will showcase this thought leadership and can be distributed
                through company websites, social media or other channels.
              </p>
              <p>
                Our partnership with <em>The Times</em> means your story will be
                presented to the biggest audience of c-suite decision-makers in
                the UK. We publish 80 reports a year in <em>The Times</em>{" "}
                (print) covering an array of business and industry topics, so we
                can ensure your message reaches an audience tuned to your
                business.
              </p>
            </div>
          </Row>
        </Container>
      </Section>
      <YouGet>
        <Container>
          <h2>What you get</h2>
          <Row>
            <Third>
              <h3>Discover</h3>
              <List>
                <li>Brand identity and narrative agenda workshop</li>
                <li>Competitor CEO profiling</li>
                <li>Editorial calendar</li>
                <li>Content style guide</li>
                <li>
                  Introductions made and relationships built with key
                  journalists
                </li>
              </List>
            </Third>
            <Third>
              <h3>Craft</h3>
              <List>
                <li>Social media audit, optimisation and training</li>
                <li>Bespoke media training at freuds’ studios</li>
                <li>Content creation: articles and blogs</li>
              </List>
            </Third>
            <Third>
              <h3>Tell</h3>
              <List>
                <li>
                  Content distribution in <em>The Times</em> (print and online)
                  and raconteur.net
                </li>
                <li>Filmed roundtable with video distribution</li>
                <li>
                  Online content in front of 110,000 senior business decision
                  makers
                </li>
              </List>
            </Third>
          </Row>
        </Container>
      </YouGet>
    </React.Fragment>
  )
}

export default withTheme(How)
