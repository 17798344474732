import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import How from "../components/sections/how"
import Cta from "../components/ui/cta"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="CEO amp: the programme"
        description="In just 180 days we will discover, craft and tell your story in order to amplify your voice and your brand."
      />
      <How />
      <Cta />
    </Layout>
  )
}

export default IndexPage
